
import { Component, Prop, Vue } from "vue-property-decorator";
import t from "@common/src/i18n/t";
@Component({})
export default class Headline extends Vue {
  @Prop({ default: t("global.Store-Management-System") })
  title: string;
  @Prop({ default: true })
  showBottomBorder: boolean;
}
